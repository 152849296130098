import React, { useState, useEffect } from 'react';
import './Game.css';
import logo from './favicon3.png';


const Game = ({ socket }) => {
  const [username, setUsername] = useState(null); // Set initial value to null
  const [enteredUsername, setEnteredUsername] = useState(""); // Temporary state for username input
  const [question, setQuestion] = useState(null);
  const [timer, setTimer] = useState(8000);
  const [score, setScore] = useState(0);
  const [leaderboard, setLeaderboard] = useState({});
  const [hasAnswered, setHasAnswered] = useState(false);
  const [fastestAnswer, setFastestAnswer] = useState(null);
  const [questionTransition, setQuestionTransition] = useState('fade-in');
  const [buttonTransition, setButtonTransition] = useState('fade-in');

  const joinGame = () => {
    if (enteredUsername) {
      setUsername(enteredUsername);
      socket.emit('setUsername', { username: enteredUsername });
    }
  };

  const handleAnswer = (isCorrect, index) => {
    // If the user has already answered, return without making any changes
    if (hasAnswered) return;
    if (timer <= 0) return; // If the timer is below 4 seconds (the pause period), don't allow the user to answer
  
    // Set that the user has answered
    setHasAnswered(true);
  
    const buttons = document.getElementsByClassName('button');
  
    // Highlight the user's selected answer
    if (isCorrect) {
      buttons[index].classList.add('button-correct');
    } else {
      buttons[index].classList.add('button-incorrect');
    }
  
    // Find and highlight the correct answer
    const correctIndex = question.options.findIndex(option => option.isCorrect);
    if (correctIndex !== -1) {
      buttons[correctIndex].classList.add('button-correct');
    }
  
    // Send answer to the server
    socket.emit('answer', { isCorrect });
  };
  
  
  
  

  // Function to receive a new question from the server
  useEffect(() => {
    socket.on('newQuestion', (newQuestion) => {
      // Start the fade-out effect
      setQuestionTransition('fade-out');
      // Start the fade-out effect for buttons
      setButtonTransition('fade-out');

      // Clear the button styles
      const buttons = document.getElementsByClassName('button');
      for (let i = 0; i < buttons.length; i++) {
          buttons[i].classList.remove('button-correct', 'button-incorrect');
      }

      // Delay the rendering of the new question to allow the fade-out to complete
      setTimeout(() => {
        setQuestion(newQuestion);
        setTimer(8000); // Reset the timer to  seconds
        setHasAnswered(false);
        setFastestAnswer(null);
        // Apply the fade-in effect
        setQuestionTransition('fade-in');
        setButtonTransition('fade-in');
      }, 1000); // 1-second delay to match the CSS transition duration
      // Apply the fade-in effect for buttons
    });
  }, [socket]);

  useEffect(() => {
    socket.on('leaderboardUpdate', (newLeaderboard) => {
      setLeaderboard(newLeaderboard);
    });
  }, [socket]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer(timer - 100), 100);
      return () => clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    socket.on('scoreUpdate', (newScore) => {
        setScore(newScore);
    });
  }, [socket]);

  useEffect(() => {
    socket.on('fastestCorrectAnswer', (data) => {
      const timeTakenInSeconds = (data.timeTaken / 1000).toFixed(3);
      setFastestAnswer({
        username: data.username,
        timeTaken: `${timeTakenInSeconds} seconds`
      });
    });
  }, [socket]);
  

  return (
    <div className="container">
      <div className="header">
        <img src={logo} alt="Trivia Tangle" className="logo" />
      </div>
      {username ? (
        <>
          <p className={`question ${questionTransition}`}>{question?.text}</p>
          {(hasAnswered || timer <= 0) && question?.funFact && (
            <div className="fun-fact">
              <h3>Fun Fact</h3>
              <p>{question.funFact}</p>
            </div>
          )}


          <div className="button-container">
            <div className="options">
              {question?.options.map((option, index) => (
                <button
                  key={index}
                  className={`button ${buttonTransition}`}
                  onClick={() => handleAnswer(option.isCorrect, index)}
                >
                  {option.text}
                </button>
              ))}
            </div>
            <div className="info-section">
              <div className="fastest-answer">
                <h3>Fastest Correct Answer</h3>
                {fastestAnswer ? (
                  <div>
                    <p>{fastestAnswer.username}</p>
                    <p>{fastestAnswer.timeTaken}</p>
                  </div>
                ) : (
                  <p>No correct answers yet</p>
                )}
              </div>
            </div>
          </div>
          <p className="timer">Timer: {timer}</p>
          <p className="score">Score: {score}</p>
          <div className="leaderboard">
            <h3>Leaderboard</h3>
            {Object.entries(leaderboard)
              .sort(([, aScore], [, bScore]) => bScore - aScore)
              .map(([userId, userScore], index) => (
                userScore !== 0 && <p key={index}>{`User ${userId}: ${userScore}`}</p>
              ))
            }
          </div>
        </>
      ) : (
        <div className="username-container">
          <input
            type="text"
            placeholder="Enter your username"
            value={enteredUsername}
            onChange={(e) => setEnteredUsername(e.target.value)}
            className="username-input"
            autoFocus // This line sets the default focus to the input box
            onKeyDown={(e) => e.key === 'Enter' && joinGame()} // This line allows the Enter key to submit the username
          />
          <button onClick={joinGame} className="username-button">Join</button>
        </div>
      )}
    </div>
  );  
  
};

export default Game;