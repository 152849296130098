import React from 'react';
import io from 'socket.io-client';
import Game from './Game';

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  withCredentials: true
});
console.log('Connecting to backend at:', process.env.REACT_APP_BACKEND_URL);


function App() {
  return (
    <div className="App">
      <Game socket={socket} />
    </div>
  );
}

export default App;
